import styled from 'styled-components'

const H6 = styled.h1`
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.67px;
  line-height: 46px;
  font-weight: normal;
  font-family: ${({theme}) => theme.fontFamily.publicSans};
  margin-bottom: 20px;
  ${({theme}) => theme.media.mobile} {
    line-height: 32px;
  }
`;

export default H6;