import React, { Component } from "react";
import Slider from "react-slick";
import styled from 'styled-components';
import ItemCarousel from '../../components/ItemCarousel/ItemCarousel'
import H4  from '../../components/H4/H4'

const StyledWrapper = styled.div`
  position: relative;
 
`;

const ImageArrow = styled.div`
  position: relative;
  cursor: pointer;
  padding-top:  ${({paddingTop}) => paddingTop ? '1px' : '0px'};
  
  :hover {
    .arrow {
      fill: ${({theme}) => theme.colors.primary};
    }
  }
  .arrow {
    fill: ${({theme}) => theme.colors.dark};
    transition: all 0.3s;
  }

  
`;


export default class Carousel extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.state = {
      height: 'auto'
    }
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  componentDidMount() {
    const h = document.querySelector('#carouselItems .slick-slider').clientHeight;
    this.setState({ height: `${h+30}px`});
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            infinite: true,
            dots: false,
            equalizeHeight: true
          }
        }
      ]
    };

    const title = this.props.title;
    const carousel = this.props.carousel;

    return (
      <StyledWrapper className="container-fluid px-0 px-md-5 mt-0 mt-md-5 pt-5 mb-0 pb-0">
        <div className="row justify-content-center text-center px-4 px-md-0 pt-3 pb-0 pb-md-5 mb-4">
          <H4 data-sal="slide-up"data-sal-delay="300"data-sal-duration="1000"data-sal-easing="ease"> {title} </H4>
        </div>
        <div id="carouselItems" data-sal="slide-up"data-sal-delay="300"data-sal-duration="1000"data-sal-easing="ease" className="row pb-3 pb-md-4 mb-3 mb-md-0">
          <div className="col-12">
            <div className="d-none d-md-flex justify-content-end align-items-center pb-3 mb-5 pr-5">
              <ImageArrow  className="pr-2" onClick={this.previous} >
                <svg className="arrow" width="33px" height="11px" viewBox="0 0 33 11" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g className="arrow" id="arrowLeft" transform="translate(-1286.000000, -2531.000000)" fill="#000000" fillRule="nonzero">
                      <path id="Line-Copy-3" d="M1295.73356,2531.25835 L1296.21654,2532.13398 L1295.77873,2532.37547 L1289.412,2535.887 L1295.49595,2535.93745 L1318.00417,2536.12502 L1318.50415,2536.12918 L1318.49582,2537.12915 L1317.99583,2537.12498 L1295.48761,2536.93741 L1289.404,2536.887 L1295.71099,2540.50361 L1296.14472,2540.75237 L1295.64722,2541.61983 L1295.21349,2541.37107 L1287.25126,2536.80456 L1286.48019,2536.36233 L1287.25853,2535.93302 L1295.29575,2531.49984 L1295.73356,2531.25835 Z"></path>
                    </g>
                  </g>
                </svg>
              </ImageArrow>
              <ImageArrow paddingTop className="pl-3" onClick={this.next} >
                <svg className="arrow" width="33px" height="11px" viewBox="0 0 33 11" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g className="arrow" id="arrowRight" transform="translate(-1349.000000, -2531.000000)" fill="#000000" fillRule="nonzero">
                      <path id="Line-Copy-2" d="M1372.35242,2531.2557 L1372.78617,2531.50443 L1380.74871,2536.07039 L1381.51981,2536.51256 L1380.7415,2536.94193 L1372.70459,2541.37566 L1372.26679,2541.61718 L1371.78375,2540.74159 L1372.22155,2540.50007 L1378.587,2536.988 L1372.50402,2536.93807 L1349.74587,2536.74998 L1349.24588,2536.74585 L1349.25415,2535.74588 L1349.75413,2535.75002 L1372.51228,2535.9381 L1378.595,2535.988 L1372.28872,2532.37192 L1371.85497,2532.1232 L1372.35242,2531.2557 Z"></path>
                    </g>
                  </g>
                </svg>
              </ImageArrow>
            </div>
            <Slider ref={c => (this.slider = c)} {...settings}>
              {carousel && carousel.map((item, index) => <ItemCarousel key={index} item={item}  height={this.state.height} />)}
            </Slider>
          </div>
        </div>
      </StyledWrapper>
    );
  }
}
