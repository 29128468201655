import React from "react"
import styled from 'styled-components';
import FluidImage from '../../components/FluidImage';

const StyledWrapper = styled.div`
  position: relative;
  margin-top: 100px;
  margin-bottom: 100px;
  max-width: 1300px;
  ${({theme}) => theme.media.mobile} {
     margin-top: 50px;
     margin-bottom: 20px;
  }
`;
const Text = styled.div`
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 32px;
  margin-left: 50px;
  font-variation-settings: 'wdth' 600, 'wght' 300;
  p:last-child {
     margin-bottom: 0px;
  }
  ${({theme}) => theme.media.mobile} {
     text-align: left;
     margin-left: 0px;
  }
`;


const LeftImageRightText = ({text, image}) => {

  return (
      <StyledWrapper className="container px-3 px-md-5 pb-3">
        <div className="row align-items-center">
          <div data-sal="slide-up"
               data-sal-delay="100"
               data-sal-duration="1000"
               data-sal-easing="ease"
               className="col-12 col-md-5 px-5 px-md-3 pr-md-5">
            <FluidImage image={image}/>
          </div>
          <div className="col-12 col-md-7 px-4 px-md-3 pl-md-5 mt-5 mt-md-0">
            <Text data-sal="slide-up"
                  data-sal-delay="300"
                  data-sal-duration="1000"
                  data-sal-easing="ease"
                  dangerouslySetInnerHTML={{__html: text}} />
          </div>
        </div>
      </StyledWrapper>
  )
}

export default LeftImageRightText
