import React from "react"
import styled from 'styled-components'
import H6 from '../Titles/H6'
import TextSmall from '../Text/TextSmall'

const Item = styled.div`
   color:  ${({theme}) => theme.colors.dark};
   margin: 0px 25px;
   height: ${props => props.height };
   ${({theme}) => theme.media.mobile} {
      margin: 0px 10px;
      background: #F9F7F7;
      text-align: center;
      padding: 30px 15px;
   }
   
   
`;
const Image = styled.img`
  margin: auto;
  max-height: 100%;
  width: auto;
`;
const ContainerImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 280px;
  margin-bottom: 30px;
  ${({theme}) => theme.media.mobile} {
    height: 150px;
    margin-bottom: 30px;
  }
`;


const ItemCarousel = ({item, height}) => {

  return (
    <Item height={height}>
      <ContainerImage>
        <Image src={item.image.sourceUrl} alt={item.image.altText}/>
      </ContainerImage>
      <H6>{item.title}</H6>
      <TextSmall>{item.text}</TextSmall>
    </Item>
  )
}

export default ItemCarousel
