import styled from 'styled-components'

const TextSmall = styled.div`
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 26px;
  font-variation-settings: 'wdth' 700, 'wght' 400;
  max-width: 430px;
`;

export default TextSmall;

