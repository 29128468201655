import React from "react"
import styled from 'styled-components';
import CertyficatesImg from "../../images/certyfikat.svg"
const StyledWrapper = styled.div`
  position: relative;
  max-width: 710px;
`;

const CertTitle = styled.span`
  font-size: 14px;
  letter-spacing: 1.75px;
  line-height: 16px;
  margin-left: 15px;
  font-variation-settings: 'wdth' 700, 'wght' 400;
`;
const CertLink = styled.a`
  color: ${({theme}) => theme.colors.dark};
  font-size: 12px;
  letter-spacing: 1.5px;
  line-height: 14px;
  font-variation-settings: 'wdth' 700, 'wght' 400;
  margin-bottom: 10px;
  transition: all 0.3s;
  :hover {
    color: ${({theme}) => theme.colors.primary};
    text-decoration: none;
  }
`;


const Certificates = ({title, list}) => {

  return (
      <StyledWrapper data-sal="slide-up"data-sal-delay="500"data-sal-duration="1000"data-sal-easing="ease" className="container px-4 px-md-3 pb-3 pt-4">
        <div className="row align-items-center px-4 px-md-0">
          <img src={CertyficatesImg} alt="certyfikat" />
          <CertTitle>{title}</CertTitle>
        </div>
        <div className="row d-flex flex-column mt-3 px-4 px-md-0">
          {list && list.map((item) => <CertLink href={item.file && item.file.sourceUrl} target="_blank" download rel="nofollow">{item.name}</CertLink>)}
        </div>
      </StyledWrapper>
  )
}

export default Certificates
