import React from "react"
import styled from 'styled-components';
import H1 from '../../components/H1/H1'

const StyledWrapper = styled.div`
  position: relative;
`;

const ContainerText = styled.div`
  max-width: 800px;
`;
const Text = styled.div`
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 34px;
  font-variation-settings: 'wdth' 700,'wght' 300;
  ${({theme}) => theme.media.mobile} {
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 0.75px;
      line-height: 28px;
      text-align: center;
    
  }
`;

const TitleAndText = ({title, text}) => {
  return (
    <StyledWrapper className="container pt-0 pt-md-5 pb-2 pb-md-5">
      <div className="row justify-content-center px-3 text-center pt-0">
        <H1 data-sal="slide-up"
            data-sal-delay="300"
            data-sal-duration="1000"
            data-sal-easing="ease" textCenter>{title}</H1>
      </div>
      <ContainerText className="container text-center">
        <div className="row pt-2 pt-md-3">
          <div className="col-12">
            <Text
              data-sal="slide-up"
              data-sal-delay="500"
              data-sal-duration="1000"
              data-sal-easing="ease"
              dangerouslySetInnerHTML={{__html: text}}/>
          </div>
        </div>
      </ContainerText>
    </StyledWrapper>
  )
}

export default TitleAndText
